<template>
<div id="app" class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="/" rel="home">
            GBWhatsApp
          </a>
        </p>
      </div>

      <div id="primary-menu" class="main-nav">
        <ul id="menu-primary-menu" class="menu sf-menu">
          <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="/" aria-current="page">Home</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a :href="downloadURL">Download</a>
          </li>
          <!-- @click="gotodownload()" -->
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="blogs">Blogs</a>
          </li>
          <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="/gb-whatsapp-old-version">Old Version</a>
            </li>
          <!-- <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <div class="language-selector">
              <select id="language-select" v-model="selectedLanguage" placeholder="Select Language" @change="changeLanguage">
                <option v-for="(data, i) in lang" :key="`lang_${i}`" :value="data.lang">
                  {{ data.val }}
                </option>
              </select>
            </div>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <article>
            <div class="inside-article">
              <div class="entry-content" itemprop="text">
                <div class="wp-block-cover alignwide has-parallax" style="min-height: 200px">
                  <span aria-hidden="true" class="wp-block-cover__background has-background-dim-80 has-background-dim wp-block-cover__gradient-background has-background-gradient" style="background: linear-gradient(180deg, #0078ff 0%, rgb(0, 0, 0) 99%)" />
                  <!-- <div role="img" class="wp-block-cover__image-background wp-image-622 has-parallax" style="background-position: 50% 50%; background-image: url(wp-content/uploads/2022/12/BG-2K.webp)"></div> -->
                  <div class="wp-block-cover__inner-container">
                    <div class="wp-block-image ic sdw">
                      <figure class="aligncenter size-full">
                        <img decoding="async" width="512" height="512" src="../assets/logo.webp" alt="GBWhatsApp" class="wp-image-615">
                      </figure>
                    </div>

                    <h1 id="gbwhatsapp-pro" class="has-text-align-center hd">
                      GBWhatsApp
                    </h1>

                    <div class="code-block code-block-4" style="margin: 8px auto; text-align: center; display: block; clear: both">
                      <p class="has-text-align-center v">
                        Version: <strong>18.20</strong> | Size: <strong>77MB</strong>
                      </p>
                    </div>

                    <p class="has-text-align-center d">
                      Chatting and calling with extra custom features in GB WhatsApp is a new trend for WhatsApp users. GB WhatsApp is a modified version of WhatsApp which contains more features and privacy options. It allows users to hide online status, read deleted messages, unread messages and more.
                    </p>

                    <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                      <div class="wp-block-button">
                        <a class="wp-block-button__link wp-element-button" @click="downloadClick">Download GBWhatsApp
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="beesads" />

                <!-- Table of Contents -->
                <div id="toc_container" class="no_bullets">
                  <section class="table-content" v-show="!tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/minus.svg" alt="minus">
                    </div>

                    <ul class="toc_list">
                      <li>
                        <a href="#TBC_0"><span class="toc_number toc_depth_1">1.</span> Your account has been banned</a>
                      </li>
                      <li>
                        <a href="#TBC_1"><span class="toc_number toc_depth_1">2.</span> How to link GB WhatsApp as a Linked Device</a>
                      </li>
                      <li>
                        <a href="#TBC_2"><span class="toc_number toc_depth_2">3.</span> Why do people prefer using GB WhatsApp？</a>
                      </li>
                      <li>
                        <a href="#TBC_3"><span class="toc_number toc_depth_3">4.</span> Difference between GB WhatsApp and WhatsApp</a>
                      </li>
                      <li>
                        <a href="#TBC_4"><span class="toc_number toc_depth_4">5.</span> GB WhatsApp Features</a>
                      </li>
                      <li>
                        <a href="#TBC_5"><span class="toc_number toc_depth_5">6.</span> Screenshot</a>
                      </li>
                      <li>
                        <a href="#TBC_6"><span class="toc_number toc_depth_6">7.</span> How to download GB WhatsApp Apk for Android?</a>
                      </li>
                      <li>
                        <a href="#TBC_7"><span class="toc_number toc_depth_7">8.</span> Requirements to download GB WhatsApp</a>
                      </li>
                      <li>
                        <a href="#TBC_8"><span class="toc_number toc_depth_8">9.</span> GB WhatsApp Update</a>
                      </li>
                      <li>
                        <a href="#TBC_9"><span class="toc_number toc_depth_9">10.</span> GB WhatsApp for PC</a>
                      </li>
                      <li>
                        <a href="#TBC_10"><span class="toc_number toc_depth_10">11.</span> GB WhatsApp for iPhone</a>
                      </li>
                      <li>
                        <a href="#TBC_11"><span class="toc_number toc_depth_11">12.</span> How to switch to the official WhatsApp app</a>
                      </li>
                      <li>
                        <a href="#TBC_12"><span class="toc_number toc_depth_12">13.</span> Top 5 WhatsApp Mods</a>
                      </li>
                      <li>
                        <a href="#TBC_13"><span class="toc_number toc_depth_13">14.</span> Is GBWhatsApp safe to use?</a>
                      </li>
                      <li>
                        <a href="#TBC_14"><span class="toc_number toc_depth_14">15.</span> FAQ About GBWhatsApp</a>
                      </li>
                      <li>
                        <a href="#TBC_15"><span class="toc_number toc_depth_15">16.</span> Conclusion</a>
                      </li>
                    </ul>

                  </section>
                  <section class="table-content hide" v-show="tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/plus.svg" alt="plus">
                    </div>
                  </section>

                </div>

                <h2 class="h">
                  <span id="TBC_0">
                    Your account has been banned
                  </span>
                </h2>

                <p class="italic">
                  GB WhatsApp new version was relased!!
                </p>

                <p class="center bold">
                  Version : 18.20 - 2.24.16.76
                </p>

                <p>
                  You may receive a message "You are banned from using modified version of WhatsApp" after using GB WhatsApp. In this situation, users have to give up their phone number and purchase another SIM card.
                  <br>
                  However, people worry about when the GB WhatsApp can enhance their anti-ban protection. The developers have already received feedback from users across the world. So, they kept updating the new version to test stability.
                  <br>
                  Now, the new version was released, click the button to download GB WhatsApp Apk.
                </p>

                <h2 class="h">
                  <span id="TBC_1">
                    How to link GB WhatsApp as a Linked Device
                  </span>
                </h2>
                <p>
                  If your account haven't been banned, you should decrease the usage of the additional features of GBWhatsApp. And, some regular users find a better way to use GBWhatsApp app. That is <strong>Companion Device</strong>.
                </p>
                <ol>
                  <li>1. Install And Verify Official WhatsApp.</li>
                  <li>2. Open GBWhatsApp > Link as Companion Device.</li>
                  <li>3. Take Screenshot Of GBWhatsApp QR Code And Send It To Other Phone.</li>
                  <li>4. Open WhatsApp > Menu > Linked Devices > Link a Device.</li>
                  <li>5. Scan The QR Code On Official WhatsApp.</li>
                </ol>

                <h2 class="h">
                  <span id="TBC_2">
                    Why Do People Prefer Using GB WhatsApp?
                  </span>
                </h2>
                <ul>
                  <li><strong>- Extra Features: </strong>GB WhatsApp includes a variety of unique features not available on the official WhatsApp, like message scheduling, theme customization, privacy controls (such as hiding online status), and more.</li>
                  <li><strong>- User Reviews: </strong>Many users appreciate GB WhatsApp for its flexibility and added functionality. </li>
                  <li><strong>- Large User Base: </strong>The app's popularity has grown worldwide, with millions of users enjoying enhanced customization and features, especially in regions where these modifications are valued.</li>
                  <li><strong>- Compatibility with Official WhatsApp: </strong>GB WhatsApp was developed based on the official WhatsApp. Users can still interact with contacts on the official WhatsApp, making it a seamless experience for communication.</li>
                  <li><strong>- Safety Considerations: </strong>The developers claim no information leakage. But it has risks of being banned.</li>
                </ul>

                <div class="is-layout-flex wp-container-5 wp-block-columns are-vertically-aligned-center">
                  <div class="is-layout-flow wp-block-column is-vertically-aligned-center">
                    <p class="fet">
                      <i class="fa fa-check" /> Bluetick
                    </p>

                    <p class="fet">
                      <i class="fa fa-check-double" /> Double Tick
                    </p>

                    <p class="fet">
                      <i class="fa fa-dot-circle" /> Being online
                    </p>

                    <p class="fet">
                      <i class="fa fa-keyboard" /> Typing Status
                    </p>

                    <p class="fet">
                      <i class="fa fa-stop-circle" /> Recording status
                    </p>
                  </div>

                  <div class="is-layout-flow wp-block-column is-vertically-aligned-center">
                    <p class="fet">
                      <i class="fa fa-microphone" /> Microphone settings
                    </p>

                    <p class="fet">
                      <i class="fa fa-history" /> Scheduling messages
                    </p>

                    <p class="fet">
                      <i class="fa fa-reply-all" /> Auto reply
                    </p>

                    <p class="fet">
                      <i class="fa fa-cloud-download-alt" /> Save Status
                    </p>

                    <p class="fet">
                      <i class="fa fa-plane" /> DND Mode
                    </p>
                  </div>

                  <div class="is-layout-flow wp-block-column">
                    <p class="fet">
                      <i class="fa fa-ban" /> Anti-ban service
                    </p>

                    <p class="fet">
                      <i class="fa fa-fingerprint" /> Fingerprint Lock
                    </p>

                    <p class="fet">
                      <i class="fa fa-cogs" /> Themes &amp; Fonts
                    </p>

                    <p class="fet">
                      <i class="fa fa-user-circle" /> Icon Change
                    </p>

                    <p class="fet">
                      <i class="fa fa-phone-alt" /> Disable Call
                    </p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_3">
                    Difference Between GB WhatsApp and Official WhatsApp
                  </span>
                </h2>

                <figure class="wp-block-table">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>GB WhatsApp</th>
                        <th>Official WhatsApp</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Supported Systems</td>
                        <td>Android</td>
                        <td>Android, iOS, Web, Desktop</td>
                      </tr>
                      <tr>
                        <td>Customer Service</td>
                        <td>No</td>
                        <td>Help Center</td>
                      </tr>
                      <tr>
                        <td>Features</td>
                        <td>More customization and privacy settings</td>
                        <td>Features with limited functions</td>
                      </tr>
                      <tr>
                        <td>Downloads</td>
                        <td>1,000,000+</td>
                        <td>100,000,000+</td>
                      </tr>
                      <tr>
                        <td>Update Frequency</td>
                        <td>High frequency updates</td>
                        <td>Low frequency updates</td>
                      </tr>
                      <tr>
                        <td>Backup and Restore</td>
                        <td>Local folder</td>
                        <td>Google Drive</td>
                      </tr>
                      <tr>
                        <td>Business Version</td>
                        <td>No</td>
                        <td>Yes</td>
                      </tr>
                    </tbody>
                  </table>

                </figure>

                <h2 class="h">
                  <span id="TBC_4"> GB WhatsApp Features </span>
                </h2>
                <h3>Privacy Controls</h3>
                <ul>
                  <li><strong>- Hide Online Status:</strong> This feature allows users to stay invisible to their contacts even while using the app, so others can't see when they're online.</li>
                  <li><strong>- Hide Read Receipts:</strong> You can view messages without alerting the sender by hiding the blue double ticks that indicate a message has been read.</li>
                  <li><strong>- Hide Typing Indicators:</strong> Users can type freely without showing the "typing…" notification to the person on the other end, enhancing privacy.</li>
                  <li><strong>- DND (Do Not Disturb) Mode:</strong> Activates a mode that blocks internet access for GB WhatsApp only, so you won't receive messages or notifications while you focus on other tasks.</li>
                  <li><strong>- Ghost Mode:</strong> This feature hides activity from other users, such as hiding when you view a contact's status updates, adding an extra layer of discretion.</li>
                  <li><strong>- Lock App:</strong> Users can secure the app with a passcode, fingerprint, or pattern lock, adding a layer of privacy to protect personal conversations from unauthorized access.</li>
                </ul>

                <h3>Customization Options</h3>
                <ul>
                  <li><strong>- Change Fonts:</strong> GB WhatsApp offers a variety of font options, allowing users to pick a style that best suits their personal preferences.</li>
                  <li><strong>- 4,000+ Themes:</strong> With a large selection of themes, users can fully customize the look of the app, changing backgrounds, colors, and overall style to create a unique interface.</li>
                  <li><strong>- Change Icon:</strong> Users can alter the appearance of the GB WhatsApp icon on their home screen, allowing for personalized aesthetics or to make the app less recognizable.</li>
                  <li><strong>- Change Blue Ticks Icon:</strong> In addition to hiding read receipts, GB WhatsApp lets users change the icon style of the blue ticks, giving a custom feel to message indicators.</li>
                </ul>

                <h3>Messaging Tools</h3>
                <ul>
                  <li><strong>- Auto-Reply Messages:</strong> Users can set up automatic replies for specific messages or while they're away, ideal for business use or during busy times.</li>
                  <li><strong>- Message Scheduling:</strong> This tool enables users to schedule messages to be sent at a specific time, making it useful for reminders, greetings, or time-sensitive messages.</li>
                  <li><strong>- Unread Messages:</strong> The app keeps track of unread messages, even if you've opened the chat, making it easier to go back and address important conversations.</li>
                  <li><strong>- Go to the First Message:</strong> Users can jump back to the very first message in any chat, which can be helpful for reminiscing or reviewing past conversations without endless scrolling.</li>
                  <li><strong>- Anti-Deleted Messages:</strong> If you choose to use GBWhatsApp, it allows you to read deleted messages. There will be a small line under the messages sent by your contacts to notify you that this piece of message was deleted.</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_5">
                    Screenshot
                  </span>
                </h2>
                <div class="wp-block-image">
                  <figure class="aligncenter size-full">
                    <picture>
                      <source srcset="../assets/Screenshot.webp" type="image/webp">
                      <img decoding="async" loading="lazy" width="1000" height="563" src="../assets/Screenshot.webp" alt="gbwhatsapp" class="wp-image-48">
                    </picture>
                  </figure>
                </div>

                <h2 class="h">
                  <span id="TBC_6"> How to Download GB WhatsApp APK for Android </span>
                </h2>
                <ol>
                  <li>1. Enable "Unknown Sources" in Android settings.</li>
                  <li>2. Download the latest version of GB WhatsApp APK from a <a href="https://gbwhatapks.com/downloadpage" class="jump-url">trusted site</a> which ranks high in Google.</li>
                  <li>3. Open the downloaded file and follow the installation prompts.</li>
                  <li>4. Verify your phone number and customize your settings.</li>
                </ol>
                <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                  <div class="wp-block-button">
                    <a class="wp-block-button__link wp-element-button" @click="downloadClick()">Download</a>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_7"> Requirements to Download GB WhatsApp </span>
                </h2>
                <ul>
                  <li><strong>- Internet Connection: </strong>If you're in an area with bad internet or no internet, you can't use this app.</li>
                  <li><strong>- System Requirements: </strong>Requires Android OS 5.0 or higher.</li>
                  <li><strong>- Storage: </strong>Ensure at least 200MB of free space for installation and data storage.</li>
                  <li><strong>- No Root Required</strong></li>
                </ul>

                <h2 class="h">
                  <span id="TBC_8"> GB WhatsApp Update </span>
                </h2>
                <p>
                  If you have ever download GB WhatsApp 2023 or GBWhatsApp 2024 and haven't updated for a long time. Your version might already be expired. You should delete GB WhatsApp old version and install the latest one.
                </p>
                <p>
                  There are two methods to update GB WhatsApp APK:
                </p>
                <ul>
                  <li><strong>Method 1: </strong>Google GB WhatsApp on your phone and find our site: <a href="/" class="jump-url">gbapks.com.pk</a>. Obtain the latest version of GBWhatsApp APK and install it.</li>
                  <li>
                    <strong>Method 2: </strong>Check if your app notices you updating the current version.
                  </li>
                </ul>
                <p>
                  Once new version was released, we recommend you to update it. The developer will keep updating the anti-ban mechanism. To avoid being banned, you can bookmark our site and get the new version in time.
                </p>

                <h2 class="h">
                  <span id="TBC_9"> GB WhatsApp for PC </span>
                </h2>
                <p>
                  There is no official GB WhatsApp PC version. However, users can install an Android Emulator on PC.
                </p>
                <ol>
                  <li>1. Download and install Bluestacks or another emulator.</li>
                  <li>2. Download the GB WhatsApp APK on your PC.</li>
                  <li>3. Open the emulator, go to "My Apps," and install GB WhatsApp from the downloaded APK.</li>
                  <li>4. Set up your GB WhatsApp as you would on an Android device.</li>
                </ol>

                <h2 class="h">
                  <span id="TBC_10"> GB WhatsApp for iPhone </span>
                </h2>
                <ul>
                  <li><strong>No iOS Support: </strong>GB WhatsApp is unavailable on iOS. iPhone users are recommended to use the official WhatsApp, which offers similar security and privacy features.</li>
                  <li><strong>Introduction to WhatsApp iOS Version: </strong>The official WhatsApp on iOS includes features like video calls, end-to-end encryption, and iCloud backup.</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_11">
                    How to switch to the official WhatsApp app
                  </span>
                </h2>
                <p>
                  If your account hasn't been banned, but you decide to switch back to the official WhatsApp. Here is the steps to transfer your GBWhatsApp data completely to WhatsApp.
                </p>
                <ul>
                  <li>Step 1: If you need to save the GBWhatsApp data, it requires you to manually copy data from local folders. You can paste the data to the oiginal backup folder of the official WhatsApp in Google Drive.</li>
                  <li>Step 2: Uninstall GBWhatsApp, then go to Google Play Store to download <a href="https://play.google.com/store/apps/details?id=com.whatsapp&referrer=utm_source%3Dwebsite%26utm_campaign%3Dwebsite_cta_banner_test&pli=1" rel="nofollow" target="_blank" class="jump-url"></a>WhatsApp.</li>
                  <li>Step 3: It's optional. Users can re-register an account.</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_12"> Top 5 WhatsApp Mods </span>
                </h2>

                <ol>
                  <li><strong>1. GB WhatsApp</strong> - 40%</li>
                  <li><strong>2. FM WhatsApp</strong> - 25%</li>
                  <li><strong>3. YoWhatsApp</strong> - 15%</li>
                  <li><strong>4. OG WhatsApp</strong> - 10%</li>
                  <li><strong>5. WhatsApp Plus</strong> - 10%</li>
                </ol>

                <h2 class="h">
                  <span id="TBC_13"> Is GBWhatsApp Safe to Use? </span>
                </h2>
                <ul>
                  <li><strong>Security Concerns: </strong>Modified apps may pose risks, especially for sensitive data, as they are not officially supported.</li>
                  <li><strong>Recommendation: </strong>For important conversations, it's best to use the official WhatsApp, as it provides end-to-end encryption and is regularly updated for security.</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_14"> FAQ About GBWhatsApp </span>
                </h2>

                <div class="schema-faq wp-block-yoast-faq-block">
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Can I use GB WhatsApp and the official WhatsApp on the same device?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      Yes, with different phone numbers.
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Will I get banned for using GB WhatsApp?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      The anti-ban feature aims to prevent this, but there is still some risk.
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Is GB WhatsApp free to use?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      Yes, it's a free app.
                    </p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_15"> Conclusion </span>
                </h2>

                <p>
                  Summarize the appeal of GB WhatsApp for users seeking customization and extra features, while advising caution due to potential security risks. Encourage users to stay updated with the latest version and consider using the official WhatsApp for privacy-focused communication.
                </p>

                <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  </div>

  <div id="snippet-box">
    <div class="snippet-title">
      Summary
    </div>

    <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
      <div class="aio-info">
        <div class="snippet-label-img snippet-clear">
          Author Rating
        </div>
        <div class="snippet-data-img">
          <span class="star-img"><img src="@/assets/1star.png" alt="1star"><img
              src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img
              src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
              alt="1star"></span>
        </div>
        <div class="snippet-clear" />
        <div class="aggregate_sec" itemprop="aggregateRating" itemscope=""
          itemtype="https://schema.org/AggregateRating">
          <div class="snippet-label-img">
            Aggregate Rating
          </div>
          <div class="snippet-data-img">
            <span itemprop="ratingValue">3.65</span> based on
            <span class="rating-count" itemprop="ratingCount">46258</span>
            votes
          </div>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Software Name
        </div>
        <div class="snippet-data-img">
          <span itemprop="name">GBWhatsApp APK</span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Operating System
        </div>
        <div class="snippet-data-img">
          <span itemprop="operatingSystem">Android</span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Software Category
        </div>
        <div class="snippet-data-img">
          <span itemprop="applicationCategory">App</span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Price
        </div>
        <div class="snippet-data-img">
          <span itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
            <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Landing Page
        </div>
        <div class="snippet-data-img">
          <a itemprop="featureList"
            href="/" class="jump-url">https://gbapks.com.pk/</a>
        </div>
        <div class="snippet-clear" />
      </div>
    </div>
  </div>

  <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
    <footer class="site-info">
      <div class="inside-site-info grid-container">
        <div class="footer-bar">
          <a href="/privacy">Privacy Policy</a>
        </div>
        <div class="copyright-bar">
          2025 © All Rights Reserved <strong><a href="/">GBWhatsApp</a></strong>
        </div>
      </div>
    </footer>
  </div>
  <div style="height: 55px" aria-hidden="true" class="wp-block-spacer" v-if="$global.isMobileDevice()" />


  <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
    @click="downloadClick()">
    Download GBWhatsApp New
  </div>

  <ToTop />
</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
// import utlisMethods from '@/utlis/global.js';
import {
  allCommentList
} from '@/utlis/allCommentList.js';
import ToTop from '@/components/ScrollToTop.vue';
export default {
  name: 'Home',
  components: {
    ToTop,
  },
  data() {
    return {
      pageName: 'home',
      tableHide: true,
      comment: {
        name: '',
        email: '',
        content: '',
      },
      apk: null,
      clickNum: 0,
      selectedLanguage: 'en',
      lang: [{
          lang: 'en',
          val: '🇺🇸 English'
        },
        {
          lang: 'ar',
          val: '🇦🇪 اَلْعَرَبِيَّةُ'
        },
        {
          lang: 'id',
          val: '🇮🇩 Bahasa Indonesia'
        },
        {
          lang: 'po',
          val: '🇵🇹 Português'
        },
        {
          lang: 'tr',
          val: '🇹🇷 Türkçe'
        },
        {
          lang: 'in',
          val: '🇮🇳 हिंदी'
        },
      ],
    };
  },
  computed: {
    downloadURL() {
      // var params = window.location.search;
      // return `/download-gbwhatsapp-apk${params}`;
      if (typeof window === 'undefined') {
        // Server-side rendering logic
        return '/download-gbwhatsapp-apk'; // Adjust this based on your server-side logic
      } else {
        // Client-side rendering logic
        let params = window.location.search;
        return `/download-gbwhatsapp-apk${params}`;
      }
    },
  },
  mounted() {
    this.getOfficalApk();

    // window.adsTag.renderInterstitial(54982);
  },
  methods: {
    changeLanguage(lang) {
      console.log(lang);
      // 处理语言选择变化
      // 这里可以添加其他处理逻辑，例如将语言保存到cookie或localStorage中
      this.$router.push({
        name: `${this.selectedLanguage}-gb`
      });
    },
    downloadClick() {
      this.$router.push({
        name: 'download'
      });
    },
    getOfficalApk(type = 1) {
      this.$server
        .getOfficalApk({
          domain: 'gbapks.com.pk',
          appName: 'GB_gbapks.com.pk',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    gotodownload() {
      // // const params = new URLSearchParams(window.location.href.search)
      // var params = "";
      // if (typeof window !== "undefined") {
      // 	params = window.location.search;
      // }
      // // window.location.href = "/download-gbwhatsapp-apk" + params;
      // return `/download-gbwhatsapp-apk${params}`;
      // utlisMethods.download(
      // 	"https://file.cmpc.fun/upapp/1699966982678/GBWhatsApp_2.23.20.632_gw_11_14_2101_sec_sign.apk"
      // );
      window.location.href = `https://gbapp.pro?apps=gb&domain=gbapks.com.pk&appName=GB_gbapks.com.pk`;

      // this.$global.download(this.apk);
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    jump(url) {
      window.location.href = url;
    },
  },
};
</script>
