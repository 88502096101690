<template>
  <div v-if="showButton" id="scroll-to-top" class="scroll-to-top" @click="scrollToTop">
    <img src="@/assets/up-arrow.svg" alt="Scroll to top" class="scroll-icon" />
  </div>
</template>

<script>
export default {
  name: 'ScrollToTop',
  data() {
    return {
      showButton: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    checkScrollPosition() {
      this.showButton = window.scrollY > 300;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.checkScrollPosition);
    this.checkScrollPosition();
  },
  destroyed() {
    window.removeEventListener('scroll', this.checkScrollPosition);
  }
};
</script>

<style scoped>
#scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #0078ff;
  border: none;
  border-radius: 50%;
  padding: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
}

#scroll-to-top:hover {
  opacity: 0.8;
}

.scroll-icon {
  width: 20px;
  height: 20px;
}

#scroll-to-top:active {
  transform: scale(0.95);
}

@media screen and (max-width: 768px) {
  #scroll-to-top {
    bottom: 65px;
    right: 20px;
  }
}
</style>
